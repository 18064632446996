import React from 'react';
import { Container, Content } from '../../components/home/homeStyledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import ForgottenPasswordRequestBlock from '../../components/MyAccount/ForgottenPasswordRequestBlock';
import callApi from '../../services/api';
import Layout from '../../layouts/index';

class ForgottenPasswordRequest extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.sendForgottenPasswordRequest = this.sendForgottenPasswordRequest.bind(this);
  }

  sendForgottenPasswordRequest() {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    callApi('sendForgottenPasswordRequest', 'post', { email, password })
      .then(() => {
        this.setState({ success: true, error: false, isLoading: false });
      })
      .catch(() => {
        this.setState({ success: false, error: true, isLoading: false });
      });
  }

  render() {
    return (
      <Layout>
        <Container>
          <Content>
            <MenuBar />
            <ForgottenPasswordRequestBlock
              isLoading={this.state.isLoading}
              error={this.state.error}
              success={this.state.success}
              inputChange={(field, value) => this.setState({ [field]: value })}
              sendForgottenPasswordRequest={this.sendForgottenPasswordRequest}
            />
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

export default ForgottenPasswordRequest;
